

import keys from "config/keys";
import { CryptoJS } from 'components/lib/npm'

// console.log(keys.app.secretKey, "-----secretKeys-------->");
export const encryption = (data) => {

  let ciphertext = keys.app.envType === 'local_value'
    ? data
    : CryptoJS.AES.encrypt(JSON.stringify(data), keys.app.secretKey).toString();
  return ciphertext;
};

// TODO: Decrypt Data
export const decryption = (data) => {
  if (!data) return null
  let decryptedData = null;

  try {
    if (keys.app.envType === 'local_value') {
      decryptedData = data
    } else {
      let bytes = CryptoJS.AES.decrypt(data, keys.app.secretKey);
      decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }

  } catch (error) {
    console.error(error.message);
  }
  return decryptedData;
};








//! Encryption and decryption For Socket.io 



let secretKey = 'dskdsfresi0987950jklerscxzsa8972438734dsadaswewrew89271xzcxzcZ980734843389';
secretKey = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Base64).substr(0, 32);

export const encryptSocketData = (data) => {
  const iv = CryptoJS.lib.WordArray.random(16);
  const cipher = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Utf8.parse(secretKey), { iv: iv });
  const encrypted = iv.concat(cipher.ciphertext);
  return CryptoJS.enc.Base64.stringify(encrypted);
}

export const decryptSocketData = (hash) => {
  const ciphertext = CryptoJS.enc.Base64.parse(hash);
  const iv = CryptoJS.lib.WordArray.create(ciphertext.words.slice(0, 4)); // Extract the IV
  const encryptedText = CryptoJS.lib.WordArray.create(ciphertext.words.slice(4)); // Extract the ciphertext

  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: encryptedText },
    CryptoJS.enc.Utf8.parse(secretKey),
    { iv: iv }
  );

  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
}