// useSocketEventsListener.js
import { useEffect } from 'components/lib/react-npm';
import useSocketSlice from 'store/socket-store/useSocketSlice';
import keys from 'config/keys';
import { decryptSocketData } from 'utils/crypto';

export function useSocketEventsListener(events, socket) {
    const socketDecryptedResponse = (res) => {

        if (typeof res === 'string') {
            if (keys.app.envType !== 'local_value') {
                return decryptSocketData(res);
            }
            return res;
        } else {
            console.error("Unexpected data type received:", res);
            return res; // or handle the error appropriately
        }
    };


    useEffect(() => {
        if (socket?.connected) {
            // console.log("Socket is connected. Setting up event listeners.");

            for (const event of events) {
                socket.on(event.name, (res) => {
                    const decryptedData = socketDecryptedResponse(res);
                    event.handler(decryptedData);
                });
            }

            return () => {
                // console.log("Cleaning up event listeners.");
                for (const event of events) {
                    socket.off(event.name);
                }
            };
        }
    }, [socket, events]);
}